$primary-color:#bd0000;

/*$secondary-color:#8c8c8c;*/
$secondary-color:$primary-color;
$secondary-darker: #3b3b3b;
$secondary-darker-2: $primary-color;
$footer-accents: $primary-color; 
$footer-icons-color: $primary-color;
$linked-text-color: #1d67e0;

$input-default-border: 0 !important;
$input-default-background: #eee;

$input-default-border: 0;

@import "../app.scss";

.latest-updates {
    .nav-side-menu{
        ul{
            background: #ececec;
        }
        .sub-menu {
            background: #ffffff73 !important;
        }
    }
}
.right-links li, footer .left-links li {
    border-right: 1px solid $footer-accents;
}
.new-txt p {
    font-size: 17rem;
}
.new-txt h2, .new-txt h1, .event-post-txt h2 {
        font-size: 16rem;
}
.event-post-full .event-post-txt{
    padding: 0rem 30rem 0;
}
.event-post-full{
    display: grid;
    grid-template-columns: 125px 1fr;
    @media (max-width: $mobile-breakpoint) {
        grid-template-columns: 1fr;
    }
    .thumb {
        width: auto;
    }
    .event-post-content {
        width: 100%;
    }
    &-one-column{
        grid-template-columns: 1fr;
    }
}
@media (max-width: $mobile-breakpoint) {
    .thumb img{
        max-width: 100%;
        margin: auto;
        display: block;
        width: auto;
    }
}
.subheader {
    padding: 15px 15px !important;
    background: white;
    ul li{
        a{
            color: black;
        }
        &:after {
            color: black;
        }
    }
    &:before{
            background: none !important;

    }
    &:after{
        background: none;
    }
}
.title-box{
    background: #1d67e0;
    padding: 17px;
    border-radius: 3px;
    margin-bottom: 15px;
    h1 {
        font-size: 23rem;
        font-weight: 600;
        color: white;
        text-align: center;
    }
}
.latest-updates .nav-side-menu .sub-menu li {
        margin-left: 0px;
}
.latest-updates .nav-side-menu .sub-menu li {
        padding-left: 20px !important;
}
.latest-updates ul {
    margin: 0 -10rem;
}
.latest-updates ul li.activated_closest_parent {
    &:after{
        height: 0 !important;

    }
    &:before{
        background: $primary-color;
        position: absolute;
        left: -100px;
        height: 100%;
        width: calc(100% + 200px);
        z-index: 1;
        content: '';
        top: 0;
    }
    strong{
        position: relative;
        z-index: 100;
    }
}
.latest-updates ul li.activated_closest_parent {
    //background: $primary-color;
}
.latest-updates ul li .activated {
    color: white;
}
.latest-updates ul li
{
    &:after {
        transition: none !important;
        -webkit-transition: none !important;
        left: 0 !important;
    }
  
    &:hover{
        //position: relative;
        //background: none !important;
        //&:before{
        //    background: $primary-color;
        //    position: absolute;
        //    left: -100px;
        //    height: 100%;
        //    width: calc(100% + 100px);
        //    z-index: 1;
        //    content: '';
        //    top: 0;
        //}
        > strong{
            position: relative;
            z-index: 10;
            a, a:hover{
                color: white !important;
            }
        }
        &:after {
            width: calc(100% + 200px);
            z-index: 0;
            left: -100px !important;
        }
    }
}
.search_query, #search_query {
    width: 100% !important;
    background: #eee !important;
    color: #222 !important;
    &::placeholder {
        color: #222 !important;
    }
}
.topbar-search .widget_search_form_submit {
    background: $primary-color;
}
.topbar-search .widget_search_form_input {
    color: $text-color !important;
}
.btn {
    &-primary {
        background: $primary-color;
        border-radius: 0;
        margin: 0;

    }
}
//.header-two{
    .btn {
        &-primary {
            height: 40px !important;
            line-height: 25px;
            border: 0;
            &:hover, &:active, &:focus {
                background: $primary-color;
            }
        }
        &-success {
            color: #fff;
            display: inline-block;
            padding: 6px 12px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-image: none;
            border: 1px solid transparent;
            line-height: 26px;
            border-radius: 0;
        }
    }
//}
.toogleHeaderMenu {
    .sub-menu a, .logo-nav-row .dropdown-menu > li > a {
        line-height: 24rem;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    }
    position:fixed; 
    top: 0;
    z-index: 1000;
    width: 100%;
    background: white;
    padding: 20px;
    
    &-navbar {
        position: fixed;
            top: 0;
        z-index: 1000;
        width: 100%;
        background: white;
        padding: 20px;
    }
    .nav-side-menu {
        padding-top: 100px;
        height: 100vh;
        overflow: scroll;
    }
    .menu-content {
        @media(max-width: 1000px){
            margin: 0;
            padding: 0;
        }
        li {
            list-style: none;
        }
        .fas {
            display: none !important;
        } 
        &>.menu_child_wrapper{
            
        
        &>li:not(.hidden-position) {
           display: none !important;
        }
        &> .hidden-position > .sub-menu> .sub-menu_inner{
            display: grid ;
            grid-template-columns: repeat(4, 25%);
            grid-template-rows:  1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            
            @media(max-width: 1000px){
                grid-template-columns: repeat(2, 1fr);

            }
            @media(max-width: 800px){
                grid-template-columns: repeat(1, 1fr);

            }
            &>li:not(.hidden-position) {
                    background: $primary-color;
                    a { 
                    color: white !important; 
                    }

            }
            &>.menu_child_wrapper{
                &>li:not(.hidden-position) {
                    background: $primary-color; 
                    a { 
                    color: white !important; 
                    }
                }
                 &>li > .sub-menu {
                     display: block !important;
                }
            }
/*            &>li:nth-child(1) {
                 grid-area: 1 / 1 / 2 / 2;
            }
            &>li:nth-child(2) {
                 grid-area: 2 / 1 / 3 / 2;
            }
            &>li:nth-child(3) {
                 grid-area: 1 / 2 / 2 / 3;
            }
            &>li:nth-child(4) {
                 grid-area: 2 / 2 / 3 / 3;
            }
            &>li:nth-child(5) {
                 grid-area: 1 / 3 / 2 / 4;
            }
            &>li:nth-child(6) {
                grid-area: 2 / 3 / 3 / 4;
            }
            &>li:nth-child(7) {
                grid-area: 1 / 4 / 2 / 5;
            }
            &>li:nth-child(8) {
                grid-area: 2 / 4 / 3 / 5;*/
            /*}*/

           
        }
        }
        .sub-menu {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
            li {
                padding: 0rem 2rem !important;
            }
        }
    }
}
body.menuActivated{
    overflow: hidden;
}
.telex {
    background: $primary-color;
    color: white;
    font-size: 1.1em;

    p {
        color: white;
    }
    a {
        color: white;
        text-decoration: underline;
    }
}
.newsTabs .tab-content .news-meta{
    display: none;
}
.content-columns{
    display: flex;
    flex-wrap: wrap;
    .borderedBox {
            height: 100%;
    }
}
@media(max-width: $mobile-breakpoint){
    .headerGallery {
        .gallery-3-col{
            li {
                width: 100%;
            }
            li:first-child, li:last-child {
                display: none;
            }
        }
    }
}
.right-links a:focus {
    color: white;
}
footer .textwidget {
    color: white;
}
.spanJednostkaNazwa {
    p {
        margin: 0 !important;
    }
}
.headerGallery {
    padding: 0;
    ul.gallery-3-col {
        margin: 0 0 40rem;
        li {
            @media (min-width: $mobile-breakpoint) {
                width: 33.33333%;
            }
            padding: 0px 0rem;
            overflow: hidden;
        }
    }
}
.right-links {
    display: contents;
}
@media (min-width: $mobile-breakpoint) {
    .right-links {
        display: flex;
        justify-content: flex-end;

    }
}